import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['onboardingProgress'] | null

const initialState = null as InitialStateType

export const onboardingProgressSlice = createSlice({
  initialState,
  name: 'onboardingProgress',
  reducers: {
    setOnboardingProgress(
      state,
      action: PayloadAction<Req['setOnboardingProgress']>,
    ) {
      state = action.payload
      return state
    },
  },
})

export const onboardingProgressActions = onboardingProgressSlice.actions
export const useOnboardingProgressActions = () => {
  const dispatch = useDispatch()
  const setOnboardingProgress = useCallback(
    (payload: Req['setOnboardingProgress']) => {
      return dispatch(onboardingProgressActions.setOnboardingProgress(payload))
    },
    [dispatch],
  )
  return { setOnboardingProgress }
}

const selectOnboardingProgress = (state: StoreStateType) =>
  state.onboardingProgress

export const useOnboardingProgress = () => {
  const { setOnboardingProgress } = useOnboardingProgressActions()
  const onboardingProgress = useSelector(selectOnboardingProgress)
  return useMemo(
    () => ({ onboardingProgress, setOnboardingProgress }),
    [setOnboardingProgress, onboardingProgress],
  )
}
