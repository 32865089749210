import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Profile } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

export const defaultProfile = {
  avatar: null,
  date_of_birth: null,
  days_a_week_workout: 3,
  first_name: '',
  fitness_level: 'INTERMEDIATE',
  gender: null,
  goal: null,
  height: null,
  // kg
  height_units: 'm',
  ingredient_units: 'IMPERIAL',
  is_staff: false,
  // cm
  last_name: '',
  weight: null,
  weight_units: null,
} as Profile

export const draftProfileSlice = createSlice({
  initialState: defaultProfile,
  name: 'draftProfile',
  reducers: {
    setDraftProfile(state, action: PayloadAction<Req['setDraftProfile']>) {
      state = action.payload
      return state
    },
  },
})

export const draftProfileActions = draftProfileSlice.actions
export const useDraftProfileActions = () => {
  const dispatch = useDispatch()
  const setDraftProfile = useCallback(
    (payload: Req['setDraftProfile']) => {
      return dispatch(draftProfileActions.setDraftProfile(payload))
    },
    [dispatch],
  )
  return { setDraftProfile }
}

const selectDraftProfile = (state: StoreStateType) => state.draftProfile

export const useDraftProfile = () => {
  const { setDraftProfile } = useDraftProfileActions()
  const draftProfile = useSelector(selectDraftProfile)
  return useMemo(
    () => ({ draftProfile, setDraftProfile }),
    [setDraftProfile, draftProfile],
  )
}
