import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { AnyAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { CreateApiOptions } from '@reduxjs/toolkit/dist/query/createApi'
import { getStore, StoreStateType } from './store'
import { getStrings } from './strings'
import { Constants } from './utils/constants'
import cognito from './cognito'
import { Project } from './project'
import { REHYDRATE } from 'redux-persist'
import { QueryInfo } from '@aws-sdk/client-cloudwatch-logs/models/models_0'
import dayjs from 'dayjs'
import { logout } from './services/useUser'
import { debounce } from 'lodash'

export function extractRehydrationInfo(
  action: AnyAction,
  {
    reducerPath,
  }: {
    reducerPath: string
  },
) {
  if (action.type === HYDRATE) {
    return action.payload[reducerPath]
  }
}

const debouncedLogout = debounce(
  () => {
    logout(getStore(), {})
  },
  500,
  { leading: true },
)

export const baseApiOptions = (queryArgs?: Partial<FetchBaseQueryArgs>) => {
  const res: Pick<
    CreateApiOptions<any, any, any, any>,
    | 'baseQuery'
    | 'keepUnusedDataFor'
    | 'refetchOnReconnect'
    | 'refetchOnFocus'
    | 'extractRehydrationInfo'
  > = {
    baseQuery: fetchBaseQuery({
      baseUrl: Project.api,
      fetchFn: async (args, api, extraOptions) => {
        const result = await fetch(args)
        const isUnauthorised = result?.status === 401 || result?.status === 403
        if (isUnauthorised) {
          debouncedLogout()
        }
        return result
      },
      prepareHeaders: async (headers, { endpoint, getState }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const state = getState() as StoreStateType
        if (
          endpoint !== 'register' &&
          endpoint !== 'createConfirmEmail' &&
          endpoint !== 'createResetPassword' &&
          endpoint !== 'createResendConfirmationEmail' &&
          endpoint !== 'createForgotPassword'
        ) {
          try {
            const session = await cognito.getSession()
            const jwtToken = await session!.getAccessToken()
            const testData = state?.developerSettings?.testData
            const createTestData = state?.developerSettings?.createTestData
            if (testData) {
              headers.set('x-is-test-request', `True`)
            }
            if (createTestData) {
              headers.set('x-is-test-data', `True`)
            }
            if (jwtToken?.getJwtToken()) {
              headers.set('Authorization', `Bearer ${jwtToken?.getJwtToken()}`)
            }
          } catch (e) {}
        }

        headers.set('Accept-Language', getStrings().getLanguage())
        if (Constants.E2E) {
          headers.set('E2E-Test', '1')
        }
        return headers
      },
      ...queryArgs,
    }),
    extractRehydrationInfo: (action, { reducerPath }) => {
      if (action.type === HYDRATE) {
        return action.payload[reducerPath]
      }
    },

    refetchOnFocus: true,
    refetchOnReconnect: true,
  }
  return res
}

const transformQuery = (query: QueryInfo | undefined | null) => {
  if (query?.status !== 'fulfilled') {
    return null
  }
  return {
    ...query,
    fulfilledTimeStamp: null,
  }
}
const setDjs = dayjs()
const scheduleParams = {
  end_date: setDjs.endOf('month').format('YYYY-MM-DD'),
  start_date: setDjs.startOf('month').format('YYYY-MM-DD'),
}

const day = dayjs().format('YYYY-MM-DD')
export const service = createApi({
  ...baseApiOptions(),
  endpoints: () => ({}),
  // @ts-ignore
  extractRehydrationInfo(action, { reducerPath }) {
    if (
      (action.type === REHYDRATE ||
        action.type === '__NEXT_REDUX_WRAPPER_HYDRATE__') &&
      action.payload?.service
    ) {
      return {
        'config': {},
        'mutations': {},
        'provided': {},
        'queries': {
          'getActiveUserProgram({})': transformQuery(
            action.payload?.service?.queries['getActiveUserProgram({})'],
          ),
          'getDiscussionCategories({})': transformQuery(
            action.payload?.service?.queries['getDiscussionCategories({})'],
          ),
          'getDiscussions({"page":1,"page_size":5})': transformQuery(
            action.payload?.service?.queries[
              'getDiscussions({"page":1,"page_size":5})'
            ],
          ),
          'getIapProducts({})': transformQuery(
            action.payload?.service?.queries['getIapProducts({})'],
          ),
          'getMenstrualHealth({})': transformQuery(
            action.payload?.service?.queries['getMenstrualHealth({})'],
          ),
          'getPlans({})': transformQuery(
            action.payload?.service?.queries['getPlans({})'],
          ),
          'getPrograms({"featured":true,"page":1,"page_size":4})':
            transformQuery(
              action.payload?.service?.queries[
                'getPrograms({"featured":true,"page":1,"page_size":4})'
              ],
            ),
          'getPrograms({"page":1,"page_size":20})': transformQuery(
            action.payload?.service?.queries[
              'getPrograms({"page":1,"page_size":20})'
            ],
          ),
          [`getScheduleDay({"date":"${day}"})`]: transformQuery(
            action.payload?.service?.queries[
              `getScheduleDay({"date":"${day}"})`
            ],
          ),
          'getRecipes({"favourite":true,"page":1,"page_size":4})':
            transformQuery(
              action.payload?.service?.queries[
                'getRecipes({"favourite":true,"page":1,"page_size":4})'
              ],
            ),
          'getRecipes({"featured":true,"page":1,"page_size":4})':
            transformQuery(
              action.payload?.service?.queries[
                'getRecipes({"featured":true,"page":1,"page_size":4})'
              ],
            ),
          [`getScheduleDay({"date":true,"page":1,"page_size":4})`]:
            transformQuery(
              action.payload?.service?.queries[
                'getPrograms({"featured":true,"page":1,"page_size":4})'
              ],
            ),
          'getUpcomingWorkout({})': transformQuery(
            action.payload?.service?.queries['getUpcomingWorkout({})'],
          ),
          'getUser({})': transformQuery(
            action.payload?.service?.queries['getUser({})'],
          ),
          'getUserPrograms({"active":true})': transformQuery(
            action.payload?.service?.queries[
              'getUserPrograms({"active":true})'
            ],
          ),
          'getUserPrograms({"completed":false})': transformQuery(
            action.payload?.service?.queries[
              'getUserPrograms({"completed":false})'
            ],
          ),
          'getUserPrograms({})': transformQuery(
            action.payload?.service?.queries['getUserPrograms({})'],
          ),
          'getWebEntitlements({})': transformQuery(
            action.payload?.service?.queries['getWebEntitlements({})'],
          ),
          'getWorkoutCategories({})': transformQuery(
            action.payload?.service?.queries['getWorkoutCategories({})'],
          ),
          'getWorkouts({"featured":true,"page":1,"page_size":4})':
            transformQuery(
              action.payload?.service?.queries[
                'getWorkouts({"featured":true,"page":1,"page_size":4})'
              ],
            ),
          [`getWaterTracker({"date":"${setDjs.format('YYYY-MM-DD')}"})`]:
            transformQuery(
              action.payload?.service?.queries[
                `getWaterTracker({"date":"${setDjs.format('YYYY-MM-DD')}"})`
              ],
            ),
        },
        'subscriptions': {},
      }
    }
  },
  keepUnusedDataFor: 10,
  reducerPath: 'service',
})
