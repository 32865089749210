import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import cognito from 'common/cognito'
import { getApi } from 'common/api'
import { service } from 'common/service'
import dayjs from 'dayjs'
import toFormData from 'common/toFormData'
import { getStore } from 'common/store'
import {
  developerSettingsActions,
  developerSettingsSlice,
} from './useDeveloperSettings'

export const userService = service
  .enhanceEndpoints({ addTagTypes: [] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<Res['user'], Req['getUser']>({
        queryFn: async (args, _, _2, baseQuery) => {
          await cognito.getSession()
          // return { data: {}, error: null }
          let res
          try {
            res = await baseQuery({
              method: 'GET',
              url: `users/me/`,
            })
            return res
          } catch (e) {
            console.log(e, 'res', res)
            return res || e
          }
        },
      }),

      login: builder.mutation<{}, Req['login']>({
        queryFn: async ({ email, password }, { dispatch }) => {
          await cognito.login(email, password)
          const res = (await dispatch(
            userService.endpoints.getUser.initiate({}, { forceRefetch: true }),
          )) as any
          return res
        },
      }),

      logout: builder.mutation<{}, Req['logout']>({
        queryFn: async ({}, { dispatch }) => {
          cognito.logout()
          await dispatch(service.util.resetApiState())
          await dispatch(
            developerSettingsSlice.actions.setDeveloperSettings({
              createTestData: false,
              testData: false,
            }),
          )
          return { data: {} }
        },
      }),

      register: builder.mutation<{}, Req['register']>({
        query: (query: Req['register']) => ({
          body: query,
          method: 'post',
          url: `auth/signup`,
        }),
      }),

      updateProfile: builder.mutation<{}, Req['updateProfile']>({
        queryFn: async (query, { dispatch }, _2, baseQuery) => {
          const { avatar, file, ...profile } = query
          const body = {
            ...(profile || {}),
            date_of_birth: profile?.date_of_birth
              ? dayjs(profile?.date_of_birth).format('YYYY-MM-DD')
              : null,
          }
          const formData = toFormData(body)
          if (file) {
            formData.append('avatar', file as any)
          }
          Object
          const { data, error } = await baseQuery({
            body: formData,
            method: 'PUT',
            url: `users/me/`,
          })
          if (error?.data) {
            return { error: error?.data }
          }
          const res = (await dispatch(
            userService.endpoints.getUser.initiate({}, { forceRefetch: true }),
          )) as any
          return { data: res.data, error: res.error }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export const useUser = userService.endpoints.getUser.useQueryState
export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useUpdateProfileMutation,
  // END OF EXPORTS
} = userService

export async function logout(
  store: any,
  data: Req['logout'],
  options?: Parameters<typeof userService.endpoints.logout.initiate>[1],
) {
  return store.dispatch(userService.endpoints.logout.initiate(data, options))
}

export async function register(
  store: any,
  data: Req['register'],
  options?: Parameters<typeof userService.endpoints.register.initiate>[1],
) {
  return store.dispatch(userService.endpoints.register.initiate(data, options))
}

export async function updateProfile(
  store: any,
  data: Req['updateProfile'],
  options?: Parameters<typeof userService.endpoints.updateProfile.initiate>[1],
) {
  return store.dispatch(
    userService.endpoints.updateProfile.initiate(data, options),
  )
}

export async function getUser(
  store: any,
  data: Req['getUser'],
  options?: Parameters<typeof userService.endpoints.getUser.initiate>[1],
) {
  return store.dispatch(userService.endpoints.getUser.initiate(data, options))
}

// Call this on resolving login, if the login action returns a 401, treat it as an unconfirmed email
export const tryConfirmEmailRedirect = function (res: any) {
  if (res?.error?.status === 401) {
    cognito.getSession().then((session) => {
      const email = session.getIdToken().payload?.email
      getApi().confirmEmailRedirect(email)
    })
  }
}

export const selectUser = () =>
  userService.endpoints.getUser.select({})(getStore().getState())?.data
