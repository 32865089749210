import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['trackReps'] | null

const initialState = { enabled: true } as InitialStateType

export const trackRepsSlice = createSlice({
  initialState,
  name: 'trackReps',
  reducers: {
    setTrackReps(state, action: PayloadAction<Req['setTrackReps']>) {
      state = action.payload
      return state
    },
  },
})

export const trackRepsActions = trackRepsSlice.actions
export const useTrackRepsActions = () => {
  const dispatch = useDispatch()
  const setTrackReps = useCallback(
    (payload: Req['setTrackReps']) => {
      return dispatch(trackRepsActions.setTrackReps(payload))
    },
    [dispatch],
  )
  return { setTrackReps }
}

const selectTrackReps = (state: StoreStateType) => state.trackReps

export const useTrackReps = () => {
  const { setTrackReps } = useTrackRepsActions()
  const trackReps = useSelector(selectTrackReps)
  return useMemo(() => ({ setTrackReps, trackReps }), [setTrackReps, trackReps])
}
