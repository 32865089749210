import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['codepushProgress'] | null

const initialState = null as InitialStateType

export const codepushProgressSlice = createSlice({
  initialState,
  name: 'codepushProgress',
  reducers: {
    setCodepushProgress(
      state,
      action: PayloadAction<Req['setCodepushProgress']>,
    ) {
      state = action.payload
      return state
    },
  },
})

export const codepushProgressActions = codepushProgressSlice.actions
export const useCodepushProgressActions = () => {
  const dispatch = useDispatch()
  const setCodepushProgress = useCallback(
    (payload: Req['setCodepushProgress']) => {
      return dispatch(codepushProgressActions.setCodepushProgress(payload))
    },
    [dispatch],
  )
  return { setCodepushProgress }
}

const selectCodepushProgress = (state: StoreStateType) => state.codepushProgress

export const useCodepushProgress = () => {
  const { setCodepushProgress } = useCodepushProgressActions()
  const codepushProgress = useSelector(selectCodepushProgress)
  return useMemo(
    () => ({ codepushProgress, setCodepushProgress }),
    [setCodepushProgress, codepushProgress],
  )
}
