import Localized, { GlobalStrings, LocalizedStrings } from 'react-localization'
import { stringRecords, setStrings } from 'common/strings'

export const makeStrings = <T extends GlobalStrings<any>>(strings: T) => {
  return new Localized(strings) as LocalizedStrings<typeof strings['en']>
}

const Strings = makeStrings(stringRecords)
// make strings available to common folder
setStrings(Strings)
export default Strings
