import BaseFormat from './base/_format'
import { Utils } from './utils'
import { HEIGHT_UNIT, WEIGHT_UNIT } from 'common/types/responses'
import convert from 'common/convert'
import dayjs, { Dayjs } from 'dayjs'

export const Format = {
  ...BaseFormat,
  feetAndInches(m: number | null | undefined) {
    const value = Utils.getFeetAndInches(m || 0)
    return `${value.ft || ''}ft ${value.in ? `${value.in}in` : ''}`
  },
  height(m: number | null | undefined | string, unit: HEIGHT_UNIT) {
    let cm = '' as string | number
    let mFloat = 0
    if (m) {
      mFloat = parseFloat(`${m}`)
      cm = parseFloat(`${m}`) * 100
    }
    switch (unit) {
      case 'ft': {
        return Format.feetAndInches(parseFloat(`${m}`))
      }
      default: {
        return `${cm || ''}cm`
      }
    }
    const value = Utils.getFeetAndInches(mFloat || 0)
    return `${value.ft || ''}ft ${value.in ? `${value.in}in` : ''}`
  },

  kg(kg: number | null | undefined) {
    const value = Utils.getKg(kg || 0)
    return `${value || ''}kg`
  },
  lbs(kg: number | null | undefined) {
    const value = Utils.getLbsFromKg(kg || 0)
    return `${value || ''}lbs`
  },
  lbsAndOunces: (g: number) => {
    const value = convert(g || 0)
      .from('g')
      .to('lb')
    const { decimal, number } = Utils.splitNum(value)
    const remainder = convert(decimal).from('lb').to('oz')
    return `${number ? `${number} lb ` : ''}`
  },
  prettyDay(day: Dayjs) {
    const now = dayjs(Date.now())
    let display = ''
    if (day.date() === now.date()) {
      display = 'Today'
    } else if (day.date() - now.date() === -1) {
      display = 'Yesterday'
    } else if (day.date() - now.date() === 1) {
      display = 'Tomorrow'
    } else {
      display = day.format('Do MMMM')
    }
    return display
  },
  reps(minReps: number | null, maxReps: number | null) {
    if (maxReps === minReps || !minReps || !maxReps) {
      return `${maxReps || minReps}`
    }
    return `${minReps}-${maxReps}`
  },
  setsAndReps(sets: number, minReps: number | null, maxReps: number) {
    return `${sets} ${Format.prefixQuantity(
      sets,
      'Set',
      'Sets',
    )} | ${Format.reps(minReps, maxReps)} ${Format.prefixQuantity(
      maxReps,
      'Rep',
      'Reps',
    )}`
  },
  stAndLbs(kg: number | null | undefined) {
    const value = Utils.getLbsAndStone(kg || 0)
    return `${kg ? value.st || '0' : ''}st ${
      value.lb ? `${Math.ceil(value.lb)}lbs` : ''
    }`
  },
  weight(_kg: number | null | undefined | string, unit: WEIGHT_UNIT) {
    let kg = _kg as number
    if (typeof _kg === 'string') {
      kg = parseFloat(_kg)
    }
    switch (unit) {
      case 'st': {
        return Format.stAndLbs(kg)
      }
      case 'lbs': {
        return Format.lbs(kg)
      }
      default: {
        return `${Format.kg(kg)}`
      }
    }
  },
}
