import { FC } from 'react'
import ActiveLink from './base/ActiveLink'

import Icon from './Icon'
import { useRouter } from 'next/router'
import Button from './base/forms/Button'
import { useLogoutMutation, useUser } from 'common/services/useUser'
import Image from 'next/image'
import imageResize from 'project/image-proxy-web'
import Link from 'next/link'
import hasFeature from 'common/hasFeature'

type ComponentType = {}

const Nav: FC<ComponentType> = ({}) => {
  const { data: user } = useUser({})
  const [logout] = useLogoutMutation()
  const router = useRouter()

  const doLogout = () => {
    logout({}).then(() => {
      if (typeof window !== 'undefined') {
        router.replace('/')
      }
    })
  }

  const doLogin = () => {
    router.replace('/auth/login')
  }

  return (
    <nav className='navbar navbar-expand navbar-dark pt-4-half'>
      <div className='container-fluid col-11'>
        <div className={'navbar-collapse'} id='navbarCollapse'>
          <ul className='navbar-nav me-auto mb-2 mb-md-0'>
            <li className='nav-item'>
              <ActiveLink href={'/'}>
                <Icon name='bbbLogo' />
              </ActiveLink>
            </li>
          </ul>
          {hasFeature('live') && (
            <div onSubmit={(e) => e.preventDefault()} className='d-flex'>
              {!user ? (
                <Button size='small' onClick={doLogin} aria-label='login-btn'>
                  Login
                </Button>
              ) : (
                <div className='d-flex align-items-center'>
                  <Button
                    aria-label='logout-btn'
                    size='small'
                    onClick={doLogout}
                    theme='text'
                    className='text-white sm-text-black'
                  >
                    Logout
                  </Button>
                  <Link
                    href='/account'
                    className='nav-link'
                    aria-label='user-profile-link'
                  >
                    {user?.avatar ? (
                      <img
                        src={imageResize(user?.avatar, 44, 44)}
                        alt='avatar'
                        className='navbar-avatar me-2'
                      />
                    ) : (
                      <Image
                        src={require('public/images/avatar.png')}
                        alt='avatar'
                        className='navbar-avatar me-2'
                      />
                    )}

                    <span className='text-xs text-white text-bold sm-text-black'>
                      {user.first_name} {user.last_name}
                    </span>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Nav
