import cn from 'classnames'
import { Component, ReactNode } from 'react'
import { Utils } from 'common/utils'

export type Theme = 'success' | 'danger' | 'warning' | 'info'
export const ToastMessage = class extends Component<
  {
    title?: ReactNode
    theme?: Theme
    isRemoving?: boolean
    remove: () => void
    expiry?: number
    hideClose?: boolean
    fade?: boolean
  },
  { isShowing: boolean }
> {
  static displayName = 'ToastMessage'

  constructor(props: any) {
    super(props)
    this.state = {
      isShowing: true,
    }
  }

  remove = () => {
    this.setState({ isShowing: true })
    setTimeout(
      () => {
        this.props.remove()
      },
      this.props.fade ? 200 : 0,
    )
  }

  componentDidMount() {
    if (this.props.expiry) {
      setTimeout(() => {
        this.setState({ isShowing: true })
        this.remove()
      }, this.props.expiry)
    }

    setTimeout(() => {
      this.setState({ isShowing: false })
    }, 10)
  }

  render() {
    const className = cn({
      'fade': this.props.fade,
      'hide': this.props.isRemoving,
      'mb-4 outline p-2 shadow bg-body rounded': true,
      show: true,
      showing: this.props.fade && this.state.isShowing,
      'toast': true,
    })

    return (
      <div className={className}>
        <div className={`toast-border bg-primary`} />
        {/* eslint-disable-next-line */}
        <div className="toast-header rounded bg-light700 flex-row">
          <div className='flex-fill'>
            <span className={`mb-0 ms-2 fw-semibold text-${this.props.theme}`}>
              {this.props.title}
            </span>

            <div>{this.props.children}</div>
          </div>
          {!this.props.hideClose && (
            <i className='cursor-pointer me-2 fs-sm text-muted close fa fa-times' />
          )}
        </div>
      </div>
    )
  }
} as any

export let toast: (
  title: ReactNode,
  children: ReactNode,
  theme?: Theme,
  expiry?: number,
) => void

export const ToastContainer = class extends Component<
  {},
  {
    messages: {
      id: string
      title: ReactNode
      theme?: Theme
      children: ReactNode
      isRemoving?: boolean
      expiry: number
    }[]
  }
> {
  static displayName = 'ToastMessages'

  constructor(props: any, context: any) {
    super(props, context)
    this.state = { messages: [] }
    toast = this.toast
  }

  toast = (
    title: ReactNode,
    children: ReactNode,
    theme: Theme = 'success',
    expiry = 5000,
  ) => {
    const { messages } = this.state

    const id = Utils.GUID()
    messages.push({ children, expiry, id, theme, title })
    this.setState({ messages })
  }

  remove = (id: string) => {
    const newIndex = this.state.messages.findIndex((v) => v.id === id)
    // eslint-disable-next-line
    const newMessages = this.state.messages;
    newMessages.splice(newIndex, 1)
    this.setState({ messages: newMessages })
  }

  render() {
    return (
      <div className='toast-messages toast-container position-fixed top-0 end-0 m-4'>
        {this.state.messages.map((message) => (
          <ToastMessage
            fade
            key={message.id}
            title={message.title}
            theme={message.theme}
            isRemoving={message.isRemoving}
            remove={() => this.remove(message.id)}
            expiry={message.expiry}
          >
            {message.children}
          </ToastMessage>
        ))}
      </div>
    )
  }
}
