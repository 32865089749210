// @ts-ignore
import {
  HEIGHT_UNIT,
  INGREDIENT_UNIT,
  Profile,
  WEIGHT_UNIT,
} from 'common/types/responses'

// @ts-ignore
const DEV = global.__DEV__
  ? // @ts-ignore
    global.__DEV__
  : typeof __DEV__ === 'undefined'
  ? false
  : __DEV__

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  REQUEST_TIMEOUT: 10000,
  defaultHeightUnit: 'ft' as HEIGHT_UNIT,
  defaultIngredientUnit: 'IMPERIAL' as INGREDIENT_UNIT,
  defaultLocale: 'en',
  defaultWeightUnits: 'lbs' as WEIGHT_UNIT,
  enableSentry: !DEV,
  flags: {
    health: false,
  },
  simulate: !DEV
    ? {}
    : {
        CONFIRM_EMAIL: false,
        DEFAULT_WEIGHT_ENTRY: 1,
        DUMMY_CHANGE_PASSWORD: {
          confirm_password: 'S0l1dst8!',
          old_password: 'S0l1dst8!',
          password: 'S0l1dst8!',
        },
        DUMMY_LOGIN: {
          email: 'kyle+email@solidstategroup.com',
          password: 'S0l1dst8!',
        },
        DUMMY_REGISTER: {
          confirm_password: 'S0l1dst8!',
          email: `kyle+${Date.now()}@solidstategroup.com`,
          first_name: 'Kyle',
          last_name: 'Johnson',
          password: 'S0l1dst8!',
        },
        DUMMY_RESET_PASSWORD: {
          confirm_password: 'S0l1dst8!',
          password: 'S0l1dst8!',
        },

        FORCE_LANGUAGE: '',
        FORCE_ONBOARDING: false,
        FORCE_PAGE: null,
        FORCE_SUB_PAGE: null,
        SKIP_ONBOARDING: false,
        SKIP_PAYWALL: true,
        SKIP_SETS: false,
      },
  storedRepWeightUnits: 'kg' as WEIGHT_UNIT,
}
