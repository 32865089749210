import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['CompletedWorkout'] | null

const initialState = null as InitialStateType

export const CompletedWorkoutSlice = createSlice({
  initialState,
  name: 'CompletedWorkout',
  reducers: {
    setCompletedWorkout(
      state,
      action: PayloadAction<Req['setCompletedWorkout']>,
    ) {
      state = action.payload
      return state
    },
  },
})

export const CompletedWorkoutActions = CompletedWorkoutSlice.actions
export const useCompletedWorkoutActions = () => {
  const dispatch = useDispatch()
  const setCompletedWorkout = useCallback(
    (payload: Req['setCompletedWorkout']) => {
      return dispatch(CompletedWorkoutActions.setCompletedWorkout(payload))
    },
    [dispatch],
  )
  return { setCompletedWorkout }
}

const selectCompletedWorkout = (state: StoreStateType) => state.CompletedWorkout

export const useCompletedWorkout = () => {
  const { setCompletedWorkout } = useCompletedWorkoutActions()
  const CompletedWorkout = useSelector(selectCompletedWorkout)
  return useMemo(
    () => ({ CompletedWorkout, setCompletedWorkout }),
    [setCompletedWorkout, CompletedWorkout],
  )
}
