import BaseUtils from './base/_utils'
import convert from 'common/convert'
import { selectUser } from 'common/services/useUser'
import { Constants } from './constants'
import {
  ScheduleWorkoutDay,
  UserWorkout,
  UserWorkoutSummary,
  Workout,
  WorkoutSummary,
} from 'common/types/responses'
import dayjs from 'dayjs'

export const Utils = {
  ...BaseUtils,
  convertUserWorkoutToWorkout: (userWorkout: UserWorkout) => {
    if (!userWorkout) {
      return null
    }
    return {
      ...userWorkout.workout,
      description: '',
      equipment: [],
      featured: false,
      muscle_groups: [],
      workout_exercise_groups: userWorkout.user_workout_exercise_groups.map(
        (v, i) => ({
          exercises: v.user_exercises.map((v) => ({
            ...v.exercise,
            duration: v.duration,
            max_reps: v.max_reps,
            min_reps: v.min_reps,
            sets: v.sets,
          })),
          id: v.id,
          order: i,
          sets: v.sets,
          workout_group_type: v.workout_group_type,
        }),
      ),
      workout_type: 'Standard', // todo
    } as Workout
  },
  getFeetAndInches: (m: number | null | undefined) => {
    const value = convert(m || 0)
      .from('m')
      .to('ft')
    const { decimal, number } = Utils.splitNum(value)
    const remainder = convert(decimal).from('ft').to('in')
    return { ft: number, in: parseInt(`${Math.round(remainder)}`) }
  },
  getKg: (kg: number) => {
    const value = parseInt(`${kg || 0}`)
    if (value === kg) {
      return value
    }
    return parseFloat(value.toFixed(1))
  },
  getKgFromLbs: (lbs: number | null | undefined) => {
    const value = convert(lbs || 0)
      .from('lbs')
      .to('kg')
    return value.toFixed(3)
  },
  getKgFromStone: (st: number, lbs: number) => {
    const value = convert(st || 0)
      .from('st')
      .to('kg')
    const value2 = convert(lbs || 0)
      .from('lbs')
      .to('kg')
    return (value + value2).toFixed(3)
  },
  getLbsAndOunces: (g: number) => {
    const value = convert(g || 0)
      .from('g')
      .to('lb')
    const { decimal, number } = Utils.splitNum(value)
    const remainder = convert(decimal).from('lb').to('oz')
    return { lb: Math.round(remainder), st: number }
  },
  getLbsAndStone: (kg: number) => {
    const value = convert(kg || 0)
      .from('kg')
      .to('st')
    const { decimal, number } = Utils.splitNum(value)
    const remainder = convert(decimal).from('st').to('lbs')
    return { lb: Math.round(remainder), st: number }
  },
  getLbsFromKg: (kg: number) => {
    const value = convert(kg || 0)
      .from('kg')
      .to('lbs')
    return Math.round(value)
  },
  getLocalWeight: (grams: number | null | undefined) => {
    const state = selectUser()
    const weightUnits = state?.weight_units || Constants.defaultWeightUnits
    if (!grams) {
      return ''
    }
    if (grams < 50) {
      // it doesn't make sense to return 0.00x lbs
      return `${grams} g`
    }
    return convert(grams).from('g').to(weightUnits)
  },
  getLocaleID: (locale: string) => {
    let matchingLocale = locale
    if (!locale.includes('-')) {
      switch (locale) {
        case 'en': {
          matchingLocale = 'gb'
          break
        }
        default: {
        }
      }
      return `${locale}-${matchingLocale}`
    }
    return locale
  },
  getMFromFeetAndInches: (ft: number | null | undefined, inches: number) => {
    const value = convert(ft || 0)
      .from('ft')
      .to('m')
    const value2 = convert(inches || 0)
      .from('in')
      .to('m')
    return (value + value2).toFixed(4)
  },
  getMlFromOz: (oz: number) => {
    return parseInt(`${convert(oz).from('fl-oz').to('ml')}`)
  },
  getOzFromMl: (ml: number) => {
    return parseFloat(convert(ml).from('ml').to('fl-oz').toFixed(1))
  },
  getWorkoutParams: (
    date: string | null | undefined,
    workout: ScheduleWorkoutDay,
  ) => {
    return {
      // Hide the workout if it's a future (but not immediate next) incomplete workout
      id: workout.id!,
      scheduled_date: date ? dayjs(date).format('YYYY-MM-DD') : null,
      skippedProgramID:
        workout.status !== 'COMPLETED' ? workout.user_program_id : undefined,
    }
  },
  splitNum(_num: number) {
    const num = _num.toString().split('.')
    const number = Number(num[0])
    let decimal = null
    if (num[1]) decimal = Number(`0.${num[1]}`)
    return { decimal: decimal || 0, number }
  },
}
