import CryptoJS from 'crypto-js'
import { Project } from 'common/project'

const KEY = '6275696C742D62792D6265636B792D696D6167652D70726F78792D6B6579'
const SALT = '265473253E2B59273F454A75385C4460345E6B4D6A764C7B2457537E784B5241'
const urlSafeBase64 = (string: string) => {
  return btoa(string).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

const hexDecode = (hex: string) => {
  const res = CryptoJS.enc.Hex.parse(hex)
  return res
}

const sign = (salt: string, target: string, secret: string) => {
  const hmac = CryptoJS.algo.HMAC.create(
    CryptoJS.algo.SHA256,
    hexDecode(secret),
  )
  hmac.update(hexDecode(salt))
  hmac.update(target)
  return CryptoJS.enc.Base64.stringify(hmac.finalize())
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

export default function imageResize(
  url: string,
  width: number,
  height: number,
  format = 'jpg',
) {
  if (!url || !url.includes(Project.imageCDN)) return url
  const encoded_url = urlSafeBase64(url)
  const ratio = typeof window === 'undefined' ? 1 : window.devicePixelRatio || 1
  const processingOptions = `/size:${parseInt(`${width}`)}:${parseInt(
    `${height}`,
  )}/resizing_type:fill/dpr:${ratio}/format:${format}/`
  const path = `${processingOptions}${encoded_url}.${format}`
  const signature = sign(SALT, path, KEY)
  const res = `${Project.imageProxy}${signature}${path}`

  return res
}

export function imageResizeLoading(
  url: string,
  width: number,
  height: number,
  format = 'jpg',
) {
  if (!url) return url
  const encoded_url = urlSafeBase64(url)
  const ratio = typeof window === 'undefined' ? 1 : window.devicePixelRatio || 1
  const _width = parseInt(`${width / 3}`)
  const _height = parseInt(`${height / 3}`)
  const processingOptions = `/size:${_width}:${_height}/resizing_type:fill/blur:10/dpr:${ratio}/format:${format}/`
  const path = `${processingOptions}${encoded_url}.${format}`
  const signature = sign(SALT, path, KEY)
  const res = `${Project.imageProxy}${signature}${path}`

  return res
}
