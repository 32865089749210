import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['developerSettings']

const initialState = {} as InitialStateType

export const developerSettingsSlice = createSlice({
  initialState,
  name: 'developerSettings',
  reducers: {
    setDeveloperSettings(
      state,
      action: PayloadAction<Req['setDeveloperSettings']>,
    ) {
      state = action.payload
      return state
    },
  },
})

export const developerSettingsActions = developerSettingsSlice.actions
export const useDeveloperSettingsActions = () => {
  const dispatch = useDispatch()
  const setDeveloperSettings = useCallback(
    (payload: Req['setDeveloperSettings']) => {
      return dispatch(developerSettingsActions.setDeveloperSettings(payload))
    },
    [dispatch],
  )
  return { setDeveloperSettings }
}

const selectDeveloperSettings = (state: StoreStateType) =>
  state.developerSettings

export const useDeveloperSettings = () => {
  const { setDeveloperSettings } = useDeveloperSettingsActions()
  const developerSettings = useSelector(selectDeveloperSettings)
  return useMemo(
    () => ({ developerSettings, setDeveloperSettings }),
    [setDeveloperSettings, developerSettings],
  )
}
