import 'project/polyfill'
import { AppProps } from 'next/app'
import 'styles/Global.scss'
import LanguageHandler from 'components/LanguageHandler'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import NProgress from 'components/util/NProgress'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import { ToastContainer } from 'components/Toast'
import { Project } from 'common/project'
import cognito from 'common/cognito'
import Nav from 'components/Nav'
import Image from 'next/image'
import Link from 'next/link'
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

type ComponentType = AppPropsWithLayout

cognito.init(Project.cognito)

const AppComponent: FC<ComponentType> = ({ Component, pageProps }) => {
  useEffect(() => {
    require('bootstrap/dist/js/bootstrap.bundle.min.js')
  }, [])

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <>
        <Nav />
        <div className='bg-black'>
          <Image
            src={require('public/images/bbb-hero-becky.png')}
            className='img-fluid background-hero d-none d-md-block'
            alt='Built by Becky'
            height='900'
          />
          <div className='position-relative main'>{page}</div>
        </div>
      </>
    ))
  const router = useRouter()
  if (router.asPath.includes('sw.js')) {
    return null
  }
  return (
    <LanguageHandler>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='x-ua-compatible' content='ie=edge' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#272a2c' />
        <meta name='theme-color' content='#272a2c' />
        <title>Built by Becky</title>
      </Head>
      <NProgress />
      {getLayout(<Component {...pageProps} />, pageProps)}
      <ToastContainer />
      <div id='modal' />
      <div id='confirm' />
      <div id='alert' />
      <footer>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-1' />
            <div className='col-11'>
              <Link href='/terms'>Terms & Conditions</Link>
              <Link href='/privacy'>Privacy Policy</Link>
              <Link href='/contact-us'>Contact Us</Link>
            </div>
          </div>
        </div>
      </footer>
    </LanguageHandler>
  )
}

export default nextReduxWrapper.withRedux(AppComponent)
