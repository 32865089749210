import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['redirect'] | null

const initialState = null as InitialStateType

export const RedirectSlice = createSlice({
  initialState,
  name: 'redirect',
  reducers: {
    setRedirect(state, action: PayloadAction<Req['setRedirect']>) {
      state = action.payload
      return state
    },
  },
})

export const RedirectActions = RedirectSlice.actions
export const useRedirectActions = () => {
  const dispatch = useDispatch()
  const setRedirect = useCallback(
    (payload: Req['setRedirect']) => {
      return dispatch(RedirectActions.setRedirect(payload))
    },
    [dispatch],
  )
  return { setRedirect }
}

const selectRedirect = (state: StoreStateType) => state.redirect

export const useRedirect = () => {
  const { setRedirect } = useRedirectActions()
  const redirect = useSelector(selectRedirect)
  return useMemo(() => ({ redirect, setRedirect }), [setRedirect, redirect])
}
