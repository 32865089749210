export const Project = {
  RevenueCat: {
    apple: 'appl_vVjrseEtxJaDkhHVhBVhNVYCObt',
    google: 'goog_tStbWEnXSKUGWRASwQFMFHBPZCS',
  },
  api: 'https://production-api.builtbybecky.com/api/v1/',
  apiAuth: '',
  appleURL: 'https://apps.apple.com/app/id1665851647',
  codepush: {
    android: {
      production: '52_yx7psCRNIW2GePo3LK9dS7pJX7SUUnATWe',
      staging: 'G06ZyOr1R7HaoZ8r-l5BQ9KVFjmdcfPI0N-c9',
    },
    ios: {
      production: 'PgXR9f6H-fZipoZ8Gis9cItBqNlyzazIAAJj5',
      staging: 'Ww7zWeD8eVjMFXb6dcexd2oUWKpr4EBBYHUwH',
    },
  },
  cognito: {
    oauth: {
      domain: 'builtbybecky.auth.ca-central-1.amazoncognito.com',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },

      redirectSignIn: 'https://www.builtbybecky.com',

      redirectSignOut: 'https://www.builtbybecky.com',

      responseType: 'code',

      scope: ['profile', 'email', 'openid'],
    },
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_CI6Kvq6Fi',
    userPoolWebClientId: '4lc5qst4ucgptpae9hefb5tehb',
  },
  cognitoMobile: {
    oauth: {
      domain: 'builtbybecky.auth.ca-central-1.amazoncognito.com',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },

      redirectSignIn: 'builtbybecky://',

      redirectSignOut: 'builtbybecky://',

      responseType: 'code',

      scope: ['profile', 'email', 'openid'],
    },
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_CI6Kvq6Fi',
    userPoolWebClientId: '4lc5qst4ucgptpae9hefb5tehb',
  },
  debug: false,
  flags: {
    live: true,
  },
  formly: 'boilerplate',
  googleURL:
    'https://play.google.com/store/apps/details?id=com.builtbybecky.app',
  grecaptcher: '6Lc1mf4UAAAAAC6uZ0HaGJ3ufgwCXY4GFoNhMeBD',
  imageCDN: 'https://built-by-becky-production-media.s3.amazonaws.com/',
  imageProxy: 'https://production-images.builtbybecky.com/',
  linkAPI: 'https://production-api.builtbybecky.com/',
  logs: {
    API: false,
    COGNITO: false,
    DATA: false,
    DISPATCHER: false,
    EVENTS: true,
    PUSH_NOTIFICATIONS: false,
    SERVER: false,
    STORAGE: false,
    STORE: false,
  },
  privacy: 'https://builtbybecky.com/privacy',
  stripe:
    'pk_live_51N5sM5BHWqsU6zwa2jOxkIFCwX3h5l26qlvh1tx0RbWJD3DPRDJubOuZwcw3csYeb5MV6PmY3dnbJwn0Up7t1f2x00YhIZx28U',
  terms: 'https://builtbybecky.com/terms',
  website: 'https://builtbybecky.com',
}
