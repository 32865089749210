import configureMeasurements from 'convert-units-ssg'
// @ts-ignore
import mass from 'convert-units-ssg/lib/esm/definitions/mass'
// @ts-ignore
import volume from 'convert-units-ssg/lib/esm/definitions/volume'
// @ts-ignore
import length from 'convert-units-ssg/lib/esm/definitions/length'

/*
  `configureMeasurements` is a closure that accepts a directory
  of measures and returns a factory function (`convert`) that uses
  only those measures.
*/
const convert = configureMeasurements({
  length,
  mass,
  volume,
})

export default convert
